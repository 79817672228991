import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { helmetPageValues as hel } from '../utils/constants';
import { Layout } from '../layouts';
import { SideNav, ServiceCard, Helmet } from '../components';
import { smallTriangle, circle, square } from '../images';

const StyledScrollable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.black};
`;

const StyledWrapper = styled.div`
  height: 100vh;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  position: relative;
`;

const ServicesLayout = styled.div`
  display: grid;
  grid-template-columns: 55px 1fr 55px;
  height: 100vh;
`;

const NothingComponent = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
`;

const Services = ({ data: { design, websites, marketing } }) => {

  const servicesList = [
    {
      image: design.childImageSharp.fluid,
      header: 'Design',
      text: 'We are here to help you develop unique brand for your business.',
      learnMore: '/design',
      icon: smallTriangle,
    },
    {
      image: websites.childImageSharp.fluid,
      header: 'Websites',
      text: 'Digital house of your business that we will be the architects of.',
      learnMore: '/websites',
      icon: square,
    },
    {
      image: marketing.childImageSharp.fluid,
      header: 'Marketing',
      text: 'Gives your business endless poll of potential customers.',
      learnMore: '/marketing',
      icon: circle,
    }
  ];

  return (
    <Layout>
      <Helmet {...hel.services} />
      <ServicesLayout>
        <SideNav active="services" />
        <StyledWrapper>
          <StyledScrollable>
            {servicesList.map((service) => <ServiceCard key={service.header} {...service} />)}
          </StyledScrollable>
        </StyledWrapper>
        <NothingComponent />
      </ServicesLayout>
    </Layout>
  )
}

export const query = graphql`
  query {
    design: file(relativePath: {eq: "design.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    websites: file(relativePath: {eq: "websites.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marketing: file(relativePath: {eq: "marketing.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Services;
